import {ArrowDropDown} from "@mui/icons-material";
import axios from "axios";
import config from "../config/config";
import {GlobalTransIntl} from "./GlobalTrans";

export function inputOnlyInteger(e, setter, maxInteger = null) {
    const re = /^[1-9]\d*$/;

    if ((e.target.value === '' || re.test(e.target.value)) && (maxInteger === null || (e.target.value <= maxInteger))) {
        setter(e.target.value)
    }
}

export const getUrlParam = (param) => {
    const searchParams = new URLSearchParams(window.location.search);

    return searchParams.get(param) || '';
}

export const removeUrlParams = (param = '') => {
    if (param) {
        const urlObj = new URL(window.location.href);

        urlObj.searchParams.delete(param);

        window.history.replaceState('','', urlObj.toString());
    } else {
        window.history.replaceState('','', '/');
    }
}

export const selectIconComponent = () => <ArrowDropDown/>;

export const login = (data, state, dispatch, intl, cancelToken, errorCallback, setErrorMessage) => {
    axios.post(config.apiUrl + `/login_check`, data, config.axiosConfig(state.token, {cancelToken: cancelToken.token}))
        .then(res => {
            if (res.data.token && res.data.refresh_token) {
                dispatch({
                    type: 'setTokens',
                    payload: {
                        token: res.data.token,
                        refreshToken: res.data.refresh_token
                    }
                });
            } else {
                errorCallback(true);
            }
        })
        .catch(error => {
            if (error.response && error.response.status === 401) {
                errorCallback(true);
                setErrorMessage(GlobalTransIntl('invalid_credentials', intl));
            }
        });
}

export const isPinFinished = (pinFinished, pinRessource) => {
    return (pinFinished || pinRessource.status.id > 3);
}

export const isOcsModulePage = (pinRessource, moduleIndex, pinStarted, pinFinished) => {
    const moduleJson = pinRessource.modulesJson;

    return Object.keys(pinRessource).length && !!moduleJson[moduleIndex]?.ocs && ((pinStarted && (pinRessource.status.id > 1)) || pinFinished);
}

export const getLastModuleAnsweredAt = (moduleJson, moduleIndex, answerJson) => {
    const prevModule = moduleJson[moduleIndex - 1];
    const prevModulePages = prevModule.pages;
    let lastAnswerPageId;

    let checkIndex = prevModulePages.length - 1;

    let found = false;
    while (!found) {
        lastAnswerPageId = prevModulePages[checkIndex].id;

        if (answerJson[lastAnswerPageId] === undefined) {
            checkIndex--;
        } else {
            found = true;
        }
    }

    return answerJson[lastAnswerPageId].answeredAt;
}

export const getLastPageAnsweredAt = (moduleJson, pageIndex, moduleIndex, answerJson, startedAtDate) => {
    if (pageIndex !== 0) {
        const modulePages = moduleJson[moduleIndex];
        const lastAnswerPage = modulePages.pages[(pageIndex - 1)];

        return answerJson[lastAnswerPage.id].answeredAt;
    } else if (pageIndex === 0 && moduleIndex !== 0) {
        return getLastModuleAnsweredAt(moduleJson, moduleIndex, answerJson);
    }

    return Math.floor(new Date(startedAtDate).getTime() / 1000);
}

export const getRestTimeByTimestamps = (startedTimestamp, calculatedTime) => {
    const currentTimestamp = Math.floor(new Date().getTime() / 1000);
    const targetModuleTimestamp = startedTimestamp + calculatedTime;
    let calculatedModuleTime = 0;

    if (targetModuleTimestamp > currentTimestamp) {
        calculatedModuleTime = targetModuleTimestamp - currentTimestamp;
    }

    return calculatedModuleTime;
}