import React, {useState} from "react";
import {defineMessages} from "react-intl.macro";
import {FormattedMessage as FormattedMessageReactIntl} from "react-intl";
import Validation from "../../helper/Validation";
import axios from "axios";
import config from "../../config/config";
import {useStore} from "../../store/useStore";
import GlobalTrans from "../../helper/GlobalTrans";
import Notifications from "../../components/notifications/Notifications";
import PropTypes from 'prop-types';
import {getUrlParam, inputOnlyInteger, removeUrlParams} from "../../helper/Helper";
import {Button, Grid, TextField} from "@mui/material";

function ForgotPassword(props) {
    const { state } = useStore();
    const [email, setEmail] = useState(props.email || '');
    const [validEmail, setValidEmail] = useState(true);
    const [token, setToken] = useState(props.token || '');
    const [password, setPassword] = useState('');
    const [validPassword, setValidPassword] = useState(true);
    const [passwordRepeat, setPasswordRepeat] = useState('');
    const [validPasswordRepeat, setValidPasswordRepeat] = useState(true);
    const [formStep, setFormStep] = useState((props.email && props.token) ? 3 : 1);

    // Notifications
    const [notificationSuccess, setNotificationSuccess] = useState(false);
    const [notificationError, setNotificationError] = useState(false);
    const [notificationInfo, setNotificationInfo] = useState(false);
    const [errorMessage, setErrorMessage] = React.useState('');

    const messages = defineMessages({
        'error_email': {
            id: "forgot_password.email.invalid",
            defaultMessage: "The E-Mail is not valid!"
        },
        'error_password': {
            id: "forgot_password.password.invalid",
            defaultMessage: "The password must at least have 8 characters!"
        },
        'error_password_repeat': {
            id: "forgot_password.password_repeat.invalid",
            defaultMessage: "The Password is not the same!"
        },
    });

    const resetNotification = () => {
        setNotificationError(false);
        setNotificationSuccess(false);
        setNotificationInfo(false);
    }

    const showError = (message = '') => {
        setErrorMessage(message);
        setNotificationError(true);
    }

    const showInfo = () => {
        setNotificationInfo(true);
    }

    const showSuccess = () => {
        setNotificationSuccess(true);
    }

    const isEmailValid = () => {
        let isEmailValid = Validation.isEmail(email);
        setValidEmail(isEmailValid);

        return isEmailValid;
    }

    const isPasswordValid = () => {
        let isPasswordValid = Validation.validatePassword(password);
        setValidPassword(isPasswordValid);

        return isPasswordValid;
    }

    const isPasswordRepeatValid = () => {
        let isPasswordRepeatValid = Validation.validatePasswordRepeat(password, passwordRepeat);
        setValidPasswordRepeat(isPasswordRepeatValid);

        return isPasswordRepeatValid;
    }

    const onSubmit = (e) => {
        e.preventDefault();

        if (formStep === 1) {   // E-Mail
            resetNotification();

            if (isEmailValid()) {
                // send password forgotten request
                const data = {
                    email,
                    locale: state.locale
                };

                axios.post(config.apiUrl + `/passwordForgotten/${state.locale}`, data)
                    .then(res => {
                        if (res.data === 'success') {
                            setFormStep(2);
                            showInfo();
                        } else {
                            showError();
                        }
                    })
                    .catch(error => {
                        if (error.response && error.response.data) {
                            showError(error.response.data);
                        } else {
                            showError(error.message);
                        }
                    });
            }
        } else if (formStep === 2) {    // Token
            resetNotification();

            setFormStep(3);
        } else if (formStep === 3) {    // New Password
            let validatePassword = isPasswordValid(),
                validatePasswordRepeat = isPasswordRepeatValid();

            if (validatePassword && validatePasswordRepeat) {
                const data = {
                    password,
                    passwordRepeat,
                    email,
                    token
                };

                axios.post(config.apiUrl + `/setPassword/${state.locale}`, data)
                    .then(res => {
                        if (res.data === 'success') {
                            setFormStep(4);
                            showSuccess();

                            // Remove params from url when link from mail is used
                            if (getUrlParam('email')) {
                                removeUrlParams('email');
                            }

                            setTimeout(() => {
                                onReturn();
                            }, 5000);
                        } else {
                            showError();
                        }
                    })
                    .catch(error => {
                        if (error.response && error.response.data) {
                            showError(error.response.data);
                        } else {
                            showError();
                        }
                    });
            }
        }
    }

    const onReturn = () => {
        props.returnFunction();
    }

    const getContent = () => {
        switch (formStep) {
            case 2:
                return (
                    <Grid container spacing={2} sx={{mt: 2}}>
                        <Grid item xs={12}>
                            <TextField label={GlobalTrans('token')}
                                       id={'forgot-password-token'}
                                       autoComplete={'off'}
                                       variant="outlined"
                                       onChange={(e) => inputOnlyInteger(e, setToken)}
                                       value={token}
                                       fullWidth
                                       required
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Button variant="contained" type="submit" className='forgot-password--submit' fullWidth>
                                {
                                    GlobalTrans('submit')
                                }
                            </Button>
                        </Grid>
                        <Grid item xs={12}>
                            <Button variant="contained" color={"secondary"} type="button" className='forgot-password--back' onClick={onReturn} fullWidth>
                                {
                                    GlobalTrans('back')
                                }
                            </Button>
                        </Grid>
                    </Grid>
                );
            case 3:
                return (
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <FormattedMessageReactIntl {...messages.error_password}>
                                {
                                    error =>
                                        <TextField error={!validPassword}
                                                   label={GlobalTrans('password')}
                                                   id={'forgot-password-password'}
                                                   variant="outlined"
                                                   onChange={(e) => setPassword(e.target.value)}
                                                   helperText={error}
                                                   type={'password'}
                                                   fullWidth
                                                   required
                                        />
                                }
                            </FormattedMessageReactIntl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormattedMessageReactIntl {...messages.error_password_repeat}>
                                {
                                    error =>
                                        <TextField error={!validPasswordRepeat}
                                                   label={GlobalTrans('password_repeat')}
                                                   id={'forgot-password-password-repeat'}
                                                   variant="outlined"
                                                   onChange={(e) => setPasswordRepeat(e.target.value)}
                                                   helperText={error}
                                                   type={'password'}
                                                   fullWidth
                                                   required
                                        />
                                }
                            </FormattedMessageReactIntl>
                        </Grid>
                        <Grid item xs={12}>
                            <Button variant="contained" type="submit" className='forgot-password--submit' fullWidth>
                                {
                                    GlobalTrans('submit')
                                }
                            </Button>
                        </Grid>
                    </Grid>
                );
            case 4:
                return null;
            default:
                return (
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <FormattedMessageReactIntl {...messages.error_email}>
                                {
                                    error =>
                                        <TextField error={!validEmail}
                                                   label={GlobalTrans('email')}
                                                   id={'forgot-password-email'}
                                                   variant="outlined"
                                                   onChange={(e) => setEmail(e.target.value)}
                                                   helperText={error}
                                                   value={email}
                                                   fullWidth
                                                   required
                                        />
                                }
                            </FormattedMessageReactIntl>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Button variant="contained" type="submit" className='forgot-password--submit' fullWidth>
                                        {
                                            GlobalTrans('continue')
                                        }
                                    </Button>
                                </Grid>
                                <Grid item xs={12}>
                                    <Button variant="contained" color={"secondary"} type="button" className='forgot-password--back' onClick={onReturn} fullWidth>
                                        {
                                            GlobalTrans('back')
                                        }
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                );
        }
    }

    return (
        <React.Fragment>
            <Notifications
                success={notificationSuccess}
                error={notificationError}
                info={notificationInfo}
                successMessage={GlobalTrans('forgot_password_notification_success')}
                errorMessage={errorMessage}
                infoMessage={GlobalTrans('forgot_password_notification_info')}
                cols={{
                    xs: 12,
                    lg: 12
                }}
            />
            <form className={'forgot-password--form'} onSubmit={onSubmit}>
                {
                    getContent()
                }
            </form>
        </React.Fragment>
    );
}

export default ForgotPassword;

ForgotPassword.propTypes = {
    email: PropTypes.string,
    token: PropTypes.string,
    returnFunction: PropTypes.func.isRequired,
};