import React, {useCallback, useEffect, useState} from 'react';
import {Button, CircularProgress, Grid, Paper, TextField} from "@mui/material";
import {GlobalTransIntl} from "../../helper/GlobalTrans";
import {useStore} from "../../store/useStore";
import axios from "axios";
import PinValidator from "../../helper/PinValidator";
import Notifications from "../../components/notifications/Notifications";
import {getUrlParam, removeUrlParams} from "../../helper/Helper";
import {useIntl} from "react-intl";

const SetPin = () => {
    const {state, dispatch} = useStore();
    const intl = useIntl();
    const urlPin = getUrlParam('pin');

    const [cancelToken] = useState(axios.CancelToken.source());
    const [pin, setPin] = useState('');
    const [loadingPin, setLoadingPin] = useState(!!urlPin);

    // Notifications
    const [notificationError, setNotificationError] = useState(false);
    const [notificationErrorMessage, setNotificationErrorMessage] = useState('');

    useEffect(() => {
        return () => {
            cancelToken.cancel();
        };
    }, [cancelToken])

    const validatePin = useCallback((pin) => {
        PinValidator(
            pin,
            state.locale,
            cancelToken,
            (res) => {
                dispatch({type: "setPinHasOwner", payload: res.pinHasOwner});
                dispatch({type: "setPin", payload: pin});
                dispatch({type: "setPinId", payload: res.id});

                if (res.language.length && res.languageIRI.length) {
                    dispatch({
                        type: "setLocaleAndIRI",
                        payload: {
                            'locale': res.language,
                            'iri': res.languageIRI
                        }
                    });
                }
            },
            (res) => {
                setNotificationErrorMessage(res.message);
                setNotificationError(true);
                setLoadingPin(false);
            },
            (error) => {
                if (error.response) {
                    setNotificationErrorMessage(error.response.data.detail);
                } else {
                    setNotificationErrorMessage(error.message);
                }
                setNotificationError(true);
                setLoadingPin(false);
            }
        );
    }, [cancelToken, dispatch, state.locale]);

    useEffect(() => {
        if (urlPin) {
            dispatch({type: "deletePin"});
            removeUrlParams('pin');
            validatePin(urlPin);
        }
    })

    const dispatchPin = useCallback((e) => {
        e.preventDefault();
        setNotificationError(false);
        setNotificationErrorMessage('');

        // validate pin
        validatePin(pin);
    }, [pin, validatePin]);

    return (
        !loadingPin &&
        <Grid container justifyContent={'center'}>
            <Grid item>
                <Paper className={'color-theme main-paper'}>
                    {
                        urlPin ? (
                            <CircularProgress/>
                        ) : (
                            <React.Fragment>
                                <h1>{GlobalTransIntl('set_pin_headline', intl)}</h1>
                                <Notifications
                                    grid={{mb: 3}}
                                    cols={{xs: 12}}
                                    error={notificationError}
                                    errorMessage={notificationErrorMessage}
                                />
                                <form onSubmit={dispatchPin}>
                                    <Grid container className={'set-pin'} rowSpacing={2}>
                                        <Grid item xs={12}>
                                            <TextField id="outlined-basic"
                                                       label={GlobalTransIntl('pin', intl)}
                                                       variant="outlined"
                                                       value={pin}
                                                       onChange={(e) => {
                                                           setPin(e.target.value)
                                                       }}
                                                       fullWidth
                                                       required
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Button variant={"contained"} type={"submit"} fullWidth>
                                                {GlobalTransIntl('set', intl)}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </form>
                            </React.Fragment>
                        )
                    }
                </Paper>
            </Grid>
        </Grid>
    );
};

export default SetPin;